import React from 'react';
import { ArticleCard } from './ArticleCard';
import { Article } from '../apiFolder/main';
import styled from 'styled-components';

interface ICards {
	data: Array<Article>;
}

const Cards: React.FunctionComponent<ICards> = ({ data }) => {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
	const getDate = (created: number) => {
		const date = new Date(created);
		const min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
		return `${date.getDate()} ${months[date.getMonth()]} • ${date.getHours()}:${min}`;
	};
	return (
		<div>
			{data.map(({ id, title, slug, created_at, url }) => (
				<ArticleCard key={id}>
					<a href={`/${slug}`}>
						<CardContent>
							<Avatar
								src={url}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = 'https://via.placeholder.com/150';
								}}
							/>
							<TextContainer>
								<ArticleTitle>{title}</ArticleTitle>
								<ArticleDate>{created_at && getDate(created_at)}</ArticleDate>
							</TextContainer>
						</CardContent>
					</a>
				</ArticleCard>
			))}
		</div>
	);
};

const TextContainer = styled.div`
	max-width: 78vw;
	color: black;
	padding-left: 10px;
	padding-right: 10px;
`;
const CardContent = styled.div`
	width: 100%;
	display: flex;
	height: 100px;
	flex-direction: row;
	align-items: center;
`;
const ArticleTitle = styled.div`
	max-width: 80vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const ArticleDate = styled.div`
	opacity: 0.5;
	font-size: 12px;
`;

const Avatar = styled.img`
	width: 70px;
	height: 70px;
	border-radius: 10px;
	margin: 8px;
	object-fit: cover;
`;

export default Cards;
