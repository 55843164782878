import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'components/Container';
import { Article } from '../apiFolder/main';
import Cards from '../components/Cards';

interface Context {
	pageContext: {
		arr: Array<Article>;
	};
}

const indexTemplate: React.FunctionComponent<Context> = ({ pageContext: { arr } }) => {
	return (
		<Container>
			<Helmet>
				<title>Index Page</title>
			</Helmet>
			<Cards data={arr} />
		</Container>
	);
};

export default indexTemplate;
