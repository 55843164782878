import styled from 'styled-components';

export const ArticleCard = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	height: 100px;
	border-bottom: 1px solid #f3f3f3;
	background: #fff;
	a {
		text-decoration: none;
		width: 100%;
	}
	&:hover {
		background: #ececec;
	}
`;
